export const environment = {
	production: true,
	headerValue: '74939ae2958a4018818f6365fb2b0670',
	msalConfigB2B: {
		auth: {
			clientId: '01e910ac-55e4-46e0-a975-84ffe8f91d38',
			authority: 'https://login.microsoftonline.com/f47010b4-09cd-41ff-a9ca-7e9b411249f6',
			redirectUri: 'https://eos-dev.nmviajes-it.com'
		},
		apiConfig: {
			oneConfig: {
				scopes: ['api://71aeb1b3-61d9-4b38-b75b-619b65f4a3a1/Eos.Read', 'api://71aeb1b3-61d9-4b38-b75b-619b65f4a3a1/Eos.Write'],
				uri: 'https://sat-dev2.nmviajes-it.com'
			}
		}
	},
	msalConfigB2C: {
		auth: {
			clientId: 'ef60e91a-6cd8-4299-a5b5-062f910f4a49',
			authority: 'https://login.microsoftonline.com/2c69c73c-0953-4a3c-856d-1f7a11665e30',
			//authority: 'https://expertiab2c.b2clogin.com/expertiab2c.onmicrosoft.com',
			//policy: 'B2C_1_Portal_Eos_Identity',
			redirectUri: 'https://eos-dev.nmviajes-it.com',
			//knownAuthority: 'expertiab2c.b2clogin.com',
		},
		cache: {
			cacheLocation: 'localStorage',
			storeAuthStateInCookie: false
		},
		apiConfig: {
			oneConfig: {
				scopes: ['https://expertiab2c.onmicrosoft.com/eos-identity/Eos.Write','https://expertiab2c.onmicrosoft.com/eos-identity/Eos.Read'],
				uri: 'https://sat-dev2.nmviajes-it.com'
			}
		}
	},
	securityKey: '484060556f8744ab799c69961bc5a6c190f22097',

	urlIdentityApi: 'https://sat-dev2.nmviajes-it.com/bff/identity/api/v1',
	urlAgilApi: 'https://agilservice-dev.nmviajes-it.com/APIServiceAgil/v1/api',
	urlRulesFakeName: 'https://dev2-motorvuelos.nmviajes-it.com/reglas/v1/api',
	urlRule: 'https://dev2-motorvuelos.nmviajes-it.com/reglas/v1/api',
	urlNMViajesApi: 'https://sat-dev2.nmviajes-it.com/bff/nmviajes/api/v1',
	urlFlighEngineApi: 'https://sat-dev2.nmviajes-it.com/bff/flightengine/api/v1',
	urlGeoLocate: 'https://ipapi.co/json/',

	urlTravelOulet: 'https://servicio.nmviajes.com:9443/Forms/api',

	urlTravelCApi: 'https://sat-motores.nmviajes-it.com:9443/v1/api/Booking',
	urlMotorVueloApi: 'https://sat-vuelos.nmviajes-it.com:9444/v1/api/Booking',
	urlMotorVueloApiNdc: 'https://sat-vuelos.nmviajes-it.com:9444/',
	urlMotorEmisionApi: 'https://sat-emisiones.nmviajes-it.com:9445/v1/api/Emision',
	urlMotorEmisionApiGatewayColas: 'https://agilservice-dev.nmviajes-it.com:6443',
	urlMotorEmisionApiGatewayColasEmail: 'https://agilservice-dev.nmviajes-it.com/ApiColaGateway/reportes',

	urlConfiguration: 'https://dev2-motorvuelos.nmviajes-it.com/reglas/v1/api',

	urlAgilSeguridadApi: 'https://agilservice-dev.nmviajes-it.com/APIServiceAgilSeguridad/v1/api',
	urlAgilAgenciesApi: 'https://agilservice-dev.nmviajes-it.com/APIServiceAgil/v1/api',
	urlAgilConfigurationApi: 'https://agilservice-dev.nmviajes-it.com/APIServiceAgilConfiguracion/v1/api',
	urlFeesRules: 'https://dev2-motorvuelos.nmviajes-it.com/gasto-emision/v1/api/FeeSat',

	urlMotorEmisionApiGatewayColasEmailTicket: 'https://agilservice-dev.nmviajes-it.com/ApiColaGateway/emisiones',
	urlNmConfigurationApi: 'https://serviciodev.nmviajes.com:9443/NMConfiguracionesAPI/v1/api',
	urlNmbffApi: 'https://dev-motorvuelos.nmviajes-it.com/mv',

	urlMotorVueloApiGeneral: 'https://sat-vuelos.nmviajes-it.com:9444/v1/api',
	urlSatFlighEngineApi: 'https://sat-dev.nmviajes-it.com/FlightEngine/api/v1',

	urlAgilSoapConfigurationApi: 'https://agilservice-dev.nmviajes-it.com:6443/APIServiceAgilSoap/v1/api',

    urlChangeRate: 'https://agilservice-dev.nmviajes-it.com:6443/APIExpertiaMotorVuelosNEW/v1/api',
	urlQueue: 'https://agilservice-dev.nmviajes-it.com/ApiColaGateway'
};
