import { Injectable } from '@angular/core';
import { User, Gelocalization, Application } from 'app/core/user/user.types';
import { UsersEndpoint } from 'app/providers/endpoints/users.endpoints';
import { ApiService } from 'app/providers/services/api';
import { catchError, map, Observable, ReplaySubject, tap, throwError } from 'rxjs';
import { NavigationService } from '../navigation/navigation.service';

@Injectable({ providedIn: 'root' })
export class UserService {
	private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
	private _geolocalization: ReplaySubject<Gelocalization> = new ReplaySubject<Gelocalization>(1);
	private _application: ReplaySubject<Application[]> = new ReplaySubject<Application[]>(1);

	/**
	 * Constructor
	 */
	constructor(private readonly apiService: ApiService, private navigationService: NavigationService) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Setter & getter for user
	 *
	 * @param value
	 */
	set user(value: User) {
		// Store the value
		this._user.next(value);
		//localStorage.setItem('User', JSON.stringify(value));
	}

	get user$(): Observable<User> {
		var result = this._user.asObservable();
		return result;
	}

	/**
	 * Setter & getter for Applicacion
	 *
	 * @param value
	 */
	set application(value: Application[]) {
		this._application.next(value);
	}

	get application$(): Observable<Application[]> {
		var result = this._application.asObservable();
		return result;
	}

	/**
	 * Setter & getter for Gelocalization
	 *
	 * @param value
	 */
	set gelocalization(value: Gelocalization) {
		this._geolocalization.next(value);
	}

	get gelocalization$(): Observable<Gelocalization> {
		var result = this._geolocalization.asObservable();
		return result;
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
	getGeo(): Observable<Gelocalization>
	{
		const options = {
			headers: {} // Omit headers to avoid the interceptor's custom headers
		};

		return this.apiService.get<Gelocalization>('https://ipapi.co/json/', options).pipe(
			tap((gelocalization) =>
			{
				this._geolocalization.next(gelocalization);
			}),
			catchError((error) => {
				return throwError(() => error);
			})
		);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Get the current logged in user data
	 */
	get(type: string, email: string): Observable<User> {
		return this.apiService.get<any>(`${UsersEndpoint.getUserProfile}?AuthType=${type}&Email=${email}`).pipe(
			tap((result) => {
				this._user.next(result.response.data);
				this.navigationService.getOption(result.response.data.id).subscribe();
				this.navigationService.getAplication(result.response.data.id).subscribe();
			})
		);
	}

	/**
	 * Update the user
	 *
	 * @param user
	 */
	update(user: User): Observable<any> {
		return this.apiService.put<User>('api/common/user', { user }).pipe(
			map((response) => {
				this._user.next(response);
			})
		);
	}
}
