import { Injectable } from '@angular/core';
import { ApiService } from 'app/providers/services/api';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
	private _authenticated: boolean = false;
	private _accessToken$ = new BehaviorSubject<string | null>(this._getStoredAccessToken());

	constructor(private readonly apiService: ApiService) {}

	// Observable para el access token
	get accessToken$(): Observable<string | null> {
		return this._accessToken$.asObservable();
	}

	// Método para obtener el valor actual del token
	getAccessToken(): string | null {
		return this._accessToken$.value;
	}

	signInWithAzureAD(token: string): void {
		if (token) {
            localStorage.setItem('accessToken', token);
			this._accessToken$.next(token);
			this._authenticated = true;
		} else {
			console.error('Token de acceso inválido');
		}
	}

	signOut(): void {
		if (this._accessToken$.value) {
			localStorage.removeItem('accessToken');
			this._accessToken$.next(null);
			this._authenticated = false;
		}
	}

	isAuthenticated(): boolean {
		return this._authenticated;
	}

	check(): Observable<boolean> {
		return of(this._authenticated);
	}

    // Método privado para obtener solo el access token
    private _getStoredAccessToken(): string | null {
        debugger
        const token = localStorage.getItem('accessToken');
        this._authenticated = !!token; // Actualiza el estado de autenticación
        return token;
    }
}
