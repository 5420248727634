import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { IApplication, IOption, Navigation } from 'app/core/navigation/navigation.types';
import { environment } from 'environments/environment';
import { Observable, ReplaySubject, Subject, tap } from 'rxjs';
import { UserService } from '../user/user.service';

@Injectable({ providedIn: 'root' })
export class NavigationService implements OnDestroy {
	private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _option: ReplaySubject<IOption[]> = new ReplaySubject<IOption[]>(1);
	private _aplication: ReplaySubject<IApplication[]> = new ReplaySubject<IApplication[]>(1);
	/**
	 * Constructor
	 */
	constructor(private _httpClient: HttpClient) {}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for navigation
	 */
	get navigation$(): Observable<Navigation> {
		return this._navigation.asObservable();
	}

	/**
	 * Getter for option
	 */
	get option$(): Observable<IOption[]> {
		return this._option.asObservable();
	}

	/**
	 * Getter for aplication
	 */
	get aplication$(): Observable<IApplication[]> {
		return this._aplication.asObservable();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Get all navigation data
	 */
	get(): Observable<Navigation> {
		//return this._navigation.asObservable();
		return this._httpClient.get<Navigation>('api/common/navigation').pipe(
			tap((navigation) => {
				this._navigation.next(navigation);
			})
		);
	}

	/**
	 * Get all option data
	 */
	getOption(userId: Number): Observable<IOption[]> {
		return this._httpClient.get<any>(`${environment.urlIdentityApi}/Navigation/Page?UserId=${userId}`).pipe(
			tap((result) => {
				this._option.next(result.response.data);
			})
		);
	}

	/**
	 * Get all option data
	 */
	getAplication(userId: Number): Observable<IApplication[]> {
		return this._httpClient.get<any>(`${environment.urlIdentityApi}/Navigation/Aplication?UserId=${userId}`).pipe(
			tap((result) => {
				this._aplication.next(result.response.data);
			})
		);
	}
}
