import { environment } from 'environments/environment';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { catchError, Observable, throwError } from 'rxjs';

/**
 * Diccionario para definir qué headers se deben agregar según la API.
 */
const apiHeadersMap = new Map<string, (req: HttpRequest<any>, accessToken?: string) => HttpRequest<any>>([
    [environment.urlRulesFakeName, (req) => addSubscriptionKeyHeader(req)],
    [environment.urlConfiguration, (req) => addSubscriptionKeyHeader(req)],
    [environment.urlIdentityApi, (req, accessToken) => addAuthorizationHeader(req, accessToken)],
    [environment.urlNMViajesApi, (req, accessToken) => addAuthorizationHeader(req, accessToken)],
    [environment.urlFlighEngineApi, (req, accessToken) => addAuthorizationHeader(req, accessToken)],
    [environment.urlMotorVueloApi, (req, accessToken) => addAuthorizationHeader(req, accessToken)],
    [environment.urlMotorVueloApiNdc, (req, accessToken) => addAuthorizationHeader(req, accessToken)]
]);

/**
 * Interceptor para agregar condicionalmente headers a las solicitudes HTTP.
 * Este interceptor maneja la expiración de tokens, el manejo de errores y la 
 * personalización de headers según las APIs solicitadas.
 *
 * @param req - La solicitud HTTP
 * @param next - El siguiente manejador de solicitudes HTTP
 * @returns Observable<HttpEvent<unknown>>
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    debugger
    const authService = inject(AuthService);
    const accessToken = authService.isAuthenticated() ? authService.getAccessToken() : null; // Obtén el token solo si el usuario está autenticado

    let modifiedReq = req;

    // Iterar sobre el Map para verificar si la URL corresponde a una API definida
    for (const [apiUrl, headerFn] of apiHeadersMap.entries()) {
        if (req.url.startsWith(apiUrl)) {
            modifiedReq = headerFn(req, accessToken); // Aplicar los headers correspondientes
            break; // Ya encontramos una coincidencia, no necesitamos seguir iterando
        }
    }

    // Proceder con la solicitud y manejar errores
    return next(modifiedReq).pipe(
        catchError((error: HttpErrorResponse) => {
            // Manejar errores 401 de autenticación
            if (error.status === 401) {
                handleAuthError(authService); // Manejador de error centralizado
            }

            // Propagar otros errores para ser manejados en otros niveles
            return throwError(() => error);
        })
    );
};

/**
 * Maneja los errores de autenticación, como un 401, de manera centralizada.
 * Se puede redirigir al usuario al login o mostrar un mensaje adecuado.
 *
 * @param authService - El servicio de autenticación inyectado
 */
function handleAuthError(authService: AuthService): void {
    authService.signOut(); // Cerrar sesión del usuario
    window.location.href = '/login'; // Redirigir al usuario a la página de login
}

/**
 * Agrega el header Ocp-Apim-Subscription-Key a la solicitud.
 */
function addSubscriptionKeyHeader(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
        setHeaders: {
            'Ocp-Apim-Subscription-Key': environment.headerValue
        }
    });
}

/**
 * Agrega el header de autorización (token) a la solicitud.
 */
function addAuthorizationHeader(req: HttpRequest<any>, accessToken: string | null): HttpRequest<any> {
    const headers = accessToken ? { 'Authorization': `Bearer ${accessToken}` } : {};
    return req.clone({
        setHeaders: headers
    });
}
