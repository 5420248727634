import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';
import {
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalBroadcastService,
	MsalGuard,
	MsalGuardConfiguration,
	MsalInterceptor,
	MsalInterceptorConfiguration,
	MsalService
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { provideFuse } from '@fuse';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { provideTransloco } from 'app/core/transloco/transloco.provider';
import { mockApiServices } from 'app/mock-api';

export const appConfig: ApplicationConfig = {
	providers: [
		provideAnimations(),
		provideHttpClient(),
		provideRouter(appRoutes, withPreloading(PreloadAllModules), withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),

		// Material Date Adapter
		{
			provide: DateAdapter,
			useClass: LuxonDateAdapter
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: {
				parse: {
					dateInput: 'D'
				},
				display: {
					dateInput: 'DDD',
					monthYearLabel: 'LLL yyyy',
					dateA11yLabel: 'DD',
					monthYearA11yLabel: 'LLLL yyyy'
				}
			}
		},

		// Transloco Config
		provideTransloco(),

		// Fuse
		provideAuth(),
		provideIcons(),
		provideFuse({
			mockApi: {
				delay: 0,
				services: mockApiServices
			},
			fuse: {
				layout: 'classy',
				scheme: 'light',
				screens: {
					sm: '600px',
					md: '960px',
					lg: '1280px',
					xl: '1440px'
				},
				theme: 'theme-default',
				themes: [
					{
						id: 'theme-default',
						name: 'Default'
					},
					{
						id: 'theme-brand',
						name: 'Brand'
					},
					{
						id: 'theme-teal',
						name: 'Teal'
					},
					{
						id: 'theme-rose',
						name: 'Rose'
					},
					{
						id: 'theme-purple',
						name: 'Purple'
					},
					{
						id: 'theme-amber',
						name: 'Amber'
					}
				]
			}
		})
	]
};
