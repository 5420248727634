import { HttpClient, HttpErrorResponse, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { IRequestOptions } from './api.interface';
import { Utilities } from 'app/shared/helpers/util/utilities.util';


export class ApiBaseService {
  constructor(public httpClient: HttpClient) {
    // ApiBaseService
  }

  get<T = any>(apiUrl: string, reqOptions: IRequestOptions = {}): Observable<T> {
    //apiUrl = apiUrl + '&Caller.FromBrowser=' + Utilities.getBrowserInfo() + '&Caller.FromIP='
    //console.log('apiurl', apiUrl);
    this.beforeRequest(reqOptions.showSpinner);
    const { apiUrl: parseApiUrl, reqOptions: parseReqOptions } = this.parseApiUrlAndReqOptions(apiUrl, reqOptions);

    return (this.httpClient.get<T>(parseApiUrl, parseReqOptions as any) as any).pipe(
      ...this.getDefaultOperators(parseReqOptions),
    );
  }

  getTest<T = any>(apiUrl: string, reqOptions: IRequestOptions = {}): Observable<T> {
    //console.log('apiurl', apiUrl);
    this.beforeRequest(reqOptions.showSpinner);
    const { apiUrl: parseApiUrl, reqOptions: parseReqOptions } = this.parseApiUrlAndReqOptions(apiUrl, reqOptions);

    return (this.httpClient.get<T>(parseApiUrl, parseReqOptions as any) as any).pipe(
      ...this.getDefaultOperators(parseReqOptions),
    );
  }

  post<T = any>(apiUrl: string, body: any, reqOptions: IRequestOptions = {}): Observable<T> {
    if (!this.isFormData(body)) {
      //body.Caller.FromBrowser = Utilities.getBrowserInfo();
      //body.Caller.FromIP = ''
    }

    this.beforeRequest(reqOptions.showSpinner);
    const { apiUrl: parseApiUrl, reqOptions: parseReqOptions } = this.parseApiUrlAndReqOptions(apiUrl, reqOptions);

    return (this.httpClient.post<T>(parseApiUrl, body, parseReqOptions as any) as any).pipe(
      ...this.getDefaultOperators(parseReqOptions),
    );
  }

  put<T = any>(apiUrl: string, body: any, reqOptions: IRequestOptions = {}): Observable<T> {
    this.beforeRequest(reqOptions.showSpinner);
  
    const { apiUrl: parseApiUrl, reqOptions: parseReqOptions } = this.parseApiUrlAndReqOptions(apiUrl, reqOptions);

    return (this.httpClient.put<T>(parseApiUrl, body, parseReqOptions as any) as any).pipe(
      ...this.getDefaultOperators(parseReqOptions),
    );
  }

  delete<T = any>(apiUrl: string, body?: any, reqOptions: IRequestOptions = {}): Observable<T> {
    this.beforeRequest(reqOptions.showSpinner);
    const { apiUrl: parseApiUrl, reqOptions: parseReqOptions } = this.parseApiUrlAndReqOptions(apiUrl, reqOptions);

    let httpClientDelete = this.httpClient.delete<T>(parseApiUrl, parseReqOptions as any);
    if (body) {
      const httpReq = new HttpRequest('DELETE', parseApiUrl, body, reqOptions as any);
      httpClientDelete = this.httpClient.request<T>(httpReq);
    }

    return (httpClientDelete as any).pipe(...this.getDefaultOperators(parseReqOptions));

  }

  postUploadProgress<T = any>(apiUrl: string, body: any, reqOptions: IRequestOptions = {}): Observable<T> {
    this.beforeRequest(reqOptions.showSpinner);
    const { apiUrl: parseApiUrl, reqOptions: parseReqOptions } = this.parseApiUrlAndReqOptions(apiUrl, reqOptions);
    const req = new HttpRequest('POST', parseApiUrl, body, reqOptions as any);

    return (this.httpClient.request<T>(req) as any).pipe(...this.getDefaultOperators(parseReqOptions));
  }

  onCatchError(showError = true, error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof Error) {
      console.warn('Client-Side error occured -> FRONTEND');
    } else {
      console.warn('Server-Side error occured -> BACKEND');
    }
    this.handleError(showError, error);

    return throwError(error);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeRequest(showSpinner = false): void {
    // beforeRequest
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  afterRequest(showSpinner = false): void {
    // afterRequest
  }

  handleError(showError = true, error: HttpErrorResponse): void {
    // eslint-disable-next-line no-console
    console.info(showError, error);
  }

  private getDefaultOperators(reqOptions: IRequestOptions): any[] {
    return [
      catchError(this.onCatchError.bind(this, reqOptions.showError)),
      finalize(this.afterRequest.bind(this, reqOptions.showSpinner)),
    ];
  }

  private isParameterInPath(apiUrl: string, paramKey: string): boolean {
    return !(apiUrl.indexOf(`{${paramKey}}`) === -1);
  }

  private parseApiUrlAndReqOptions(
    apiUrl: string,
    reqOptions: IRequestOptions,
  ): { apiUrl: string; reqOptions: IRequestOptions } {
    let queryParams = new HttpParams();

    const params = reqOptions.params;
    if (params) {
      Object.keys(params).forEach((paramKey: string) => {
        if (this.isParameterInPath(apiUrl, paramKey)) {
          apiUrl = apiUrl.replace(`{${paramKey}}`, params[paramKey]);
        } else {
          queryParams = queryParams.append(paramKey, params[paramKey]);
        }
      });
    }

    reqOptions = {
      ...reqOptions,
      params: queryParams,
    };

    return {
      apiUrl,
      reqOptions,
    };
  }
  isFormData(obj: any): obj is FormData {
    return obj instanceof FormData;
  }
}
