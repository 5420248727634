import { environment } from 'environments/environment';

export class UsersEndpoint {
	public static getUsersByApplicationId = `${environment.urlIdentityApi}/Applications`;
	public static getUserByIdV2 = `${environment.urlIdentityApi}/Applications/user`;
	public static getUsers = `${environment.urlIdentityApi}/Users`;
	public static updUsers = `${environment.urlIdentityApi}/Users`;
	public static getUsersByTypeIdAndNumDoc = `${environment.urlIdentityApi}/Users`;
	public static getUserById = `${environment.urlIdentityApi}/Users`;
	public static deleteUsers = `${environment.urlIdentityApi}/Users`;
	public static deleteUserApplication = `${environment.urlIdentityApi}/Users/byApplication`;
	public static getDocumentTypes = `${environment.urlIdentityApi}/Users/documentTypes`;
	public static getUserProfile = `${environment.urlIdentityApi}/Users/Profile`;

	//Webs
	public static getWebs = `${environment.urlIdentityApi}/UserWeb`;
}
