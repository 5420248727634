import { Injectable } from '@angular/core';
import { PublicClientApplication, IPublicClientApplication, PopupRequest, AuthenticationResult, LogLevel } from '@azure/msal-browser';
import { environment } from 'environments/environment';
import { AuthService } from '../auth.service';
import { UserService } from 'app/core/user/user.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DynamicMsalService {
	private msalInstance: IPublicClientApplication | null = null;

	constructor(private authService: AuthService, private userService: UserService) {}

	async initialize(authType: 'B2B' | 'B2C', config: any): Promise<void> {
		const authConfig = {
			clientId: config.auth.clientId,
			authority: config.auth.authority,
				/* authType === 'B2C'
					? `${config.auth.authority}/${config.auth.policy}` // B2C requiere incluir el flujo
					: config.auth.authority, // B2B usa la autoridad base */
			redirectUri: config.auth.redirectUri,
			/* ...(authType === 'B2C' && config.auth.knownAuthority
				? { knownAuthorities: [config.auth.knownAuthority] } // Agrega `knownAuthorities` solo para B2C
				: null) */
		};

		const systemConfig = {
			loggerOptions: {
				logLevel: LogLevel.Verbose,
				loggerCallback: (level, message) => console.log(`[MSAL][${LogLevel[level]}]: ${message}`)
			}
		};

		const cacheConfig = {
			cacheLocation: 'localStorage',
			storeAuthStateInCookie: false
		};

		this.msalInstance = new PublicClientApplication({
			auth: authConfig, // `knownAuthorities` pertenece a `auth`
			cache: cacheConfig,
			system: systemConfig
		});

		await this.msalInstance.initialize();
	}

	isInitialized(): boolean {
		return this.msalInstance !== null;
	}

	async  isAuthenticated(): Promise<boolean> {
		debugger;
    const authType = localStorage.getItem('authType');

    if (!this.isInitialized()) {
      if (!authType) {
        return false
      }

      const config = authType === 'B2B' ? environment.msalConfigB2B : environment.msalConfigB2C;
  
      // Inicializar MSAL
      await this.initialize(authType as 'B2B' | 'B2C', config); 
    }
    
		//const acouns = this.getInstance().getAllAccounts();
		const activeAccount = this.msalInstance?.getActiveAccount();
    if (activeAccount) {
      debugger
				// Obtener datos del usuario desde el servicio
				const userRole = authType === 'B2B' ? 'I' : 'E';
				await firstValueFrom(this.userService.get(userRole, activeAccount.username));
    }
		return activeAccount === null || activeAccount === undefined ? false : true;
	}

	getInstance(): IPublicClientApplication {
		if (!this.msalInstance) {
			throw new Error('MSAL instance is not initialized. Call initialize() first.');
		}
		return this.msalInstance!;
	}

	async login(authType: 'B2B' | 'B2C'): Promise<void> {
		debugger;
		/* if (!this.isInitialized()) {
			throw new Error('MSAL instance is not initialized. Call initialize() first.');
		} */

		try {
			const config = authType === 'B2B' ? environment.msalConfigB2B : environment.msalConfigB2C;

			// Inicializar MSAL
			await this.initialize(authType, config);

			// Realizar el login
			const scopes = config.apiConfig.oneConfig.scopes;
			const result = await this.msalInstance!.loginPopup({ scopes } as PopupRequest);

			if (result) {
        // Guardar tipo de autenticacion en local storage
        localStorage.setItem('authType', authType)

				// Configurar el account activo
				this.msalInstance.setActiveAccount(result.account!);

				// Guardar el token en el AuthService
				this.authService.signInWithAzureAD(result.accessToken);

				// Obtener datos del usuario desde el servicio
				const userRole = authType === 'B2B' ? 'I' : 'E';
				await firstValueFrom(this.userService.get(userRole, result.account!.username));

			}

		} catch (error) {

			console.error('Login error:', error);
			throw error;

		}
	}

	logout(): void {
		if (this.isInitialized()) {
			this.msalInstance!.logoutPopup({ mainWindowRedirectUri: '/' });
      localStorage.removeItem('accessToken');
      localStorage.removeItem('authType');
		}
	}
}
