import { inject, Injectable } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { DynamicMsalService } from '../msal/dynamicmsal.service';
import { AuthService } from '../auth.service';

export const dynamicAuthGuard: CanActivateFn = async (route, state) => {
    const authService = inject(AuthService);
    const msalService = inject(DynamicMsalService);
    const router = inject(Router);

    var isAuthenticated  = await msalService.isAuthenticated();
    // Verifica si MSAL está inicializado
    /* if (!msalService.isInitialized()) {
        console.warn('MSAL instance is not initialized. Redirecting to login.');
        router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });
        return false;
    } */

    // Verifica si hay una sesión activa en MSAL
    if (!isAuthenticated ) {
        console.warn('No active account in MSAL. Redirecting to login.');
        router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    // Verifica si el usuario está autenticado en la aplicación
    /* if (!authService.isAuthenticated()) {
        console.warn('User is not authenticated. Redirecting to login.');
        router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });
        return false;
    } */

    // Si todas las verificaciones pasan, permite el acceso
    return true;
};

export const dynamicAuthChildGuard: CanActivateChildFn = async (childRoute, state) => {
    const authService = inject(AuthService);
    const msalService = inject(DynamicMsalService);
    const router = inject(Router);
    var isAuthenticated  = await msalService.isAuthenticated();
    // Verifica si MSAL está inicializado
   /*  if (!msalService.isInitialized()) {
        console.warn('MSAL instance is not initialized. Redirecting to login.');
        router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });
        return false;
    } */

    // Verifica si hay una sesión activa en MSAL
    if (!isAuthenticated ) {
        console.warn('No active account in MSAL. Redirecting to login.');
        router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    // Verifica si el usuario está autenticado en la aplicación
/*     if (!authService.isAuthenticated()) {
        console.warn('User is not authenticated. Redirecting to login.');
        router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });
        return false;
    } */

    // Si todas las verificaciones pasan, permite el acceso
    return true;
};
