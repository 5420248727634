import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from 'app/core/shared/common/services/api';


@Injectable({
  providedIn: 'root', // or specific module
})
export class ApiService extends ApiBaseService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
}